import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TabBar from 'components/TabBar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import AccordionList from 'components/AccordionList';
import AccountsContext from 'context/AccountsContext';
import { Status } from 'context/RestAPI';
import styles from './style';

const tabNames = ['Overview', 'Subscription', 'Company', 'User'];

function AccountContent({ classes }) {

	const handleChangeAttribute = ({name, value, path}) => (event) => {
		alert(`To change the ${name} (${value}), please contact support`);
	};

	const getErrorText = (status) => {
		switch (status) {
			case Status.Init:
				return "No company account loaded. Please refresh!";
			case Status.Empty:
				return "No company account found. To contact support to set up a company account.";
			case Status.Error:
				return "Error loading company account. Please sign out and back in again. If the problem remains, please contact support!"
		}
	}

/*
	const items = [
		{
			name: 'Name', path: 'name',
			type: ObjectType.String, update: false
		}, {
			name: 'Display Name', path: 'display_name',
			type: ObjectType.String, update: true
			//onClick: handleChangeAttribute
		}, {
			name: 'URLs', path: 'urls', subfields: [
				{ name: 'Company', path: 'company', type: ObjectType.String, update: true },
				{ name: 'AGBs', path: 'agbs', type: ObjectType.String, update: true },
				{ name: 'Privacy', path: 'privacy', type: ObjectType.String, update: true }
			],
			update: false
		}, {
			name: 'Design', path: 'design', subfields: [
				{ name: 'Primary Color', path: 'primary_color', type: ObjectType.String, update: true },
				{ name: 'Seconday Color', path: 'secondary_color', type: ObjectType.String, update: true },
				{ name: 'Logo', path: 'secondary_color', type: ObjectType.String, update: false } // Img:logo
			]
		}, {
			name: 'Defaults', path: 'defaults', subfields: [
				{ name: 'Order Method', path: 'order_method', type: ObjectType.String, update: false },
				{ name: 'Language', path: 'language', type: ObjectType.String, update: false },
				{ name: 'Shipping Country', path: 'shipping_country', type: ObjectType.KeyValueList, update: false } // Obj:shipping_country
			]
		}
	];

	const htmlAccountData = (
		<AccountsContext.Consumer>
		{acc => (
			<>
			{acc?.status == Status.Loading ? (
				<div className={classes.progressbar}><CircularProgress /></div>
			) : null }

			{((acc?.status == Status.Init) ||
			(acc?.status == Status.Empty) ||
			(acc?.status == Status.Error)) ? (
				<Typography color="textSecondary" align="center">
					{getErrorText(acc?.status)}
				</Typography>
			) : null }

			{acc?.status == Status.Success ? (
				<AccordionList 
					data={acc?.accounts[acc?.selectedAccount]} 
					items={items} 
					onUpdate={handleChangeAttribute} />
			) : null }
			
			</>
		)}
		</AccountsContext.Consumer>
	); */

	return (
		<>
			<TabBar tabNames={tabNames} indexSelected={2} />
			<div className={classes.container}>
				<Paper className={classes.paper}>
					<Typography className={classes.paperSummary}>Your Company Account</Typography>

				</Paper>
			</div>
		</>
	)
}

AccountContent.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AccountContent)
