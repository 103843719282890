import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import styles from './styles';
import Actions from '../Actions';


function AccordionObjectLine({ classes, attribute, setField }) {
    return (
        <div>
        {attribute.fields.map((field, i) => (
            <ListItem key={`accordion_attribute_${i}`}>
                <ListItemText disableTypography className={classes.itemContainer}>
                    {(i == 0) ? <Typography className={classes.itemTitle}>{attribute.name}</Typography> : null}
                </ListItemText>
                <ListItemText disableTypography>
                    <Typography className={classes.itemSubTitle}>{field.getDisplayValue()}</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Actions actions={attribute.getActions(setField)(field)} item={field} fontSize="small" />
                </ListItemSecondaryAction>
            </ListItem>
        ))}
        </div>
    );
}

function AccordionObject({ classes, attribute, setField, expanded, onClick, divider }) {
    
    return (
        <>
        <AccordionObjectLine 
            classes={classes}
            attribute={attribute}
            setField={setField}
        />
        { divider ? <Divider light/> : null}
        </>
    );

    /*
    if (!subfields) {
    }

    // Sub-Fields displayed in Accordion
    return(
        <>
        <Accordion expanded={expanded === `panel_${index}`} onChange={onClick(`panel_${index}`)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className={classes.heading}>{name}</Typography>
                <Typography className={classes.secondaryHeading}>
                    {subfields.map((field) => field.name).join(', ')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List className={classes.detailsList}>
                    {subfields.map((field, field_index) => (
                        <AccordionObjectLine
                            key={`accordion_field_${field_index}`}
                            classes={classes}
                            index={`${index}_${field_index}`}
                            name={field.name}
                            data={data}
                            path={`${path}.${field.path}`}
                            type={field.type}
                            update={update}
                            onUpdate={onUpdate}
                            options={options}
                        />
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
        { divider ? <Divider light/> : null }
        </>
    );
    */
}

function AccordionList({ classes, applicationObjects, applicationObject }) {
    const [expanded, setExpanded] = useState(false);

    const handlePanelChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
    
    return (
        <List className={classes.containerList}>
            {applicationObject.attributes.filter(att => att.visible).map((attribute, index, visibleAttributes) => 
                <AccordionObject
                    key={`accordion_attribute_${index}`}
                    classes={classes}
                    attribute={attribute}
                    setField={applicationObject.selectField(attribute)}
                    expanded={expanded}
                    onClick={handlePanelChange}
                    divider={(/*!(t.subfields ||*/ (index == visibleAttributes.length -1))}
                />
            )}
        </List>
    );
}


AccordionList.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AccordionList);