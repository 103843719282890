'use strict';

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	paperSummary: {
		padding: '2%',
		textAlign: 'center',
		fontSize: theme.typography.pxToRem(20),
	},
	container: {
		padding: '48px 36px 0',
	},
	progressbar: {
		width: '100%',
		height: '50%',
		textAlign: 'center'
	}
});

export default styles;