import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import AccountContent from 'components/AccountContent';

function AccountPage({ location, authData, applications }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : '';
	return (
		<Layout location={location} 
				title={pageTitle} 
				authData={authData} 
				applications={applications}
		>
			<AccountContent authData={authData} />
		</Layout>
	)
}
AccountPage.propTypes = {
	location: PropTypes.object,
	authData: PropTypes.object.isRequired,
}
export default AccountPage
