'use strict';

const styles = theme => ({
	containerList: {
		paddingBottom: '0',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	detailsList: {
		width: '100%',
	},
	itemContainer: {
		flexBasis: '33.33% !important',
		flexShrink: 0,
		flex: 'unset !important'
	},
	itemTitle: {
		fontSize: theme.typography.pxToRem(15),
	},
	itemSubTitle: {
		fontSize: theme.typography.pxToRem(15),
		minHeight: '1.1rem',
		color: 'rgba(0, 0, 0, 0.54) !important'
	},
});

export default styles;